<div class="flex flex-row items-center">
    <div class="mr-4">
        <svg class="sicon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
             stroke-width="2" stroke-linecap="round" color="#0052cc" stroke-linejoin="round">
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="16" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12" y2="8"></line>
        </svg>
    </div>
    <div class="texto">
        <p>{{ data.message }}</p>
        <!-- 93 Chars -->
    </div>
</div>
