<div class="flex flex-row items-center">
    <div class="mr-4">
        <svg class="sicon"
             xmlns="http://www.w3.org/2000/svg"
             width="84"
             height="84"
             viewBox="0 0 84 84">
            <path
                    d="M60.566,28.674a3.281,3.281,0,0,1,0,4.641L38.553,55.327a3.282,3.282,0,0,1-4.641,0L23.434,44.848a3.281,3.281,0,1,1,4.64-4.64l8.159,8.159L55.925,28.674a3.282,3.282,0,0,1,4.641,0ZM84,42A42,42,0,1,1,42,0,41.977,41.977,0,0,1,84,42Zm-6.562,0A35.438,35.438,0,1,0,42,77.438,35.418,35.418,0,0,0,77.438,42Zm0,0"
                    fill="#00875a"
            />
        </svg>
    </div>
    <div class="texto">
        <p>{{data.message}}</p>
        <!-- 93 Chars -->
    </div>
</div>
