<div class="flex flex-row items-center">
    <div class="mr-4">
        <svg class="sicon"
            id="error"
            xmlns="http://www.w3.org/2000/svg"
            width="84"
            height="84"
            viewBox="0 0 84 84"
        >
            <g id="Group_810" data-name="Group 810">
                <g id="Group_809" data-name="Group 809">
                    <path
                        id="Path_1043"
                        data-name="Path 1043"
                        d="M42,0A42,42,0,1,0,84,42,41.976,41.976,0,0,0,42,0Zm0,77.437A35.437,35.437,0,1,1,77.437,42,35.418,35.418,0,0,1,42,77.437Z"
                        fill="#de350b"
                    />
                </g>
            </g>
            <g id="Group_812"
                data-name="Group 812"
                transform="translate(26.689 26.689)">
                <g id="Group_811" data-name="Group 811">
                    <path
                        id="Path_1044"
                        data-name="Path 1044"
                        d="M192.217,187.583l-9.716-9.716,9.716-9.716a3.277,3.277,0,1,0-4.634-4.634l-9.717,9.716-9.717-9.716a3.277,3.277,0,1,0-4.634,4.634l9.716,9.716-9.716,9.716a3.277,3.277,0,1,0,4.634,4.634l9.717-9.716,9.717,9.716a3.277,3.277,0,1,0,4.634-4.634Z"
                        transform="translate(-162.555 -162.556)"
                        fill="#de350b"
                    />
                </g>
            </g>
        </svg>
    </div>
    <div class="texto">
        <p>{{ data.message }}</p>
        <!-- 93 Chars -->
    </div>
</div>
