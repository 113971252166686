<div class="flex flex-row items-center">
    <div class="mr-4">
        <svg class="sicon"
            xmlns="http://www.w3.org/2000/svg"
            width="84"
            height="84"
            viewBox="0 0 84 84">
            <g id="Group_814" data-name="Group 814">
                <g id="Group_813" data-name="Group 813">
                    <path
                        id="Path_1045"
                        data-name="Path 1045"
                        d="M42,0A42,42,0,1,0,84,42,41.976,41.976,0,0,0,42,0Zm0,77.438A35.438,35.438,0,1,1,77.438,42,35.417,35.417,0,0,1,42,77.438Z"
                        fill="#ff991f"
                    />
                </g>
            </g>
            <g
                id="Group_816"
                data-name="Group 816"
                transform="translate(38.744 21.223)">
                <g id="Group_815" data-name="Group 815">
                    <path
                        id="Path_1046"
                        data-name="Path 1046"
                        d="M239.256,128.877A3.256,3.256,0,0,0,236,132.133V153.1a3.256,3.256,0,1,0,6.513,0v-20.97A3.256,3.256,0,0,0,239.256,128.877Z"
                        transform="translate(-236 -128.877)"
                        fill="#ff991f"
                    />
                </g>
            </g>
            <g
                id="Group_818"
                data-name="Group 818"
                transform="translate(37.604 52.902)"
            >
                <g id="Group_817" data-name="Group 817">
                    <circle
                        id="Ellipse_30"
                        data-name="Ellipse 30"
                        cx="4.396"
                        cy="4.396"
                        r="4.396"
                        fill="#ff991f"
                    />
                </g>
            </g>
        </svg>
    </div>
    <div class="texto">
        <p>{{ data.message }}</p>
        <!-- 93 Caracteres -->
    </div>
</div>
